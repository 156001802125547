<template>

  <div>
    <div style="position:absolute;width: 45px;height: 45px;" @click="slip()"><img loading="lazy" src="@/assets/slipleft.png"></div>

    <div class="ViewTitle">我的简历</div>

    <div class="MySubscriptions wrapper">
      <div>
          <div>
            <AIAnswer1></AIAnswer1>
          </div>
      </div>
      <LoginIn
          :dialogVisible="loginIn"
          @hideDialog="hideLoginIn"
          @register="showRegister"
          @forget="forget"
          @logsuccess="logsuccess"
      ></LoginIn>
      <RegisterIn
          :dialogVisible="registerIn"
          @hideDialog="hideregisterIn"
          @login="login"
      ></RegisterIn>
      <forgetIn
          :dialogVisible="forgetIn"
          @hideDialog="hidereforgetIn"
          @login="login"
      ></forgetIn>
    </div>
  </div>

</template>
<script>
import {eduUpdate, jobUpdate, proUpdate, userUpdate, workUpdate} from "@/api/user";
import { shareUrl } from "@/utils/vxshare.js";
import AIAnswer1 from "@/components/AIAnswer1.vue";
// import AIAnswer from "@/views/PrimaryClassfy/AIAnswer.vue";
import LoginIn from "@/views/userlog/LoginIn.vue";
import forgetIn from "@/views/userlog/forgetIn.vue";
import RegisterIn from "@/views/userlog/RegisterIn.vue";
export default {
  name: "AIresume",
  components: { RegisterIn, forgetIn, LoginIn,AIAnswer1},
  data() {
    return {
      dialogVisibleInfo: false,
      registerIn:false,
      forgetIn:false,
      loginIn:false,
      showMask: true, // 控制是否显示蒙版
      isMobile: false,
      editInfo:0,
      jobAdd:1,
      languageSelect:'',
      baseURL: this.$utils.baseURL,
      labelPosition: "left",
      avator: require("@/assets/avator.png"),
      options: [],
      optionsCity:[],
      optionsType:[],
      optionsIndustry:[],
      optionsSalary:[],
      optionsEdu:[],
      file: {},
      form: {},
      userId: localStorage.getItem("userId"),
      // userId:2,
      token: localStorage.getItem("access_token" ),
      // token: "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImI0ODQ4ZGRlLTg4ODEtNGFhNy1iNzRmLTgwZTM5NmMxZmU2YyJ9.LMY_3ane52i9K_vJ3NaBeF5rkMx-jCSLPZI2rFClTkqOVAT3amV1O-FJkZD4BHJyo1wOv-J6K-eDdKzdCyF6UQ",

    };
  },
  mounted() {
    setTimeout(() => {
      this.showMask = false; // 在一段时间后隐藏蒙版，触发过渡效果
    }, 1000); // 设置一个延迟时间，例如1秒后隐藏蒙版
    window.scrollTo(0, 0); // 在页面加载时滚动到页面顶部
    // Check if the device width is less than 768px (example for mobile)
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
  },
  computed: {},
  created() {

    if(localStorage.getItem('language')){
      this.languageSelect=localStorage.getItem('language')
    }else{
      this.languageSelect='zh'
    }
    this.getUser();
    this.jobStatus()
    this.jobCity()
    this.jobType()
    this.jobIndustry()
    this.jobSalary()
    this.jobEdu()
    let url = window.location.host
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url:url,
        title:'油气人才',
        text:'全球油气行业专业人才求职招募平台，AI辅助简历优化，在线雇主及时沟通，更多油气行业岗位，快来油气人才吧！',
      }
      shareUrl(shareData);
    }, 1000);
  },
  methods: {
    slip(){
      this.$router.go(-1)

    },
    hideDialog() {
      this.dialogVisible = false;
    },
    hideDialogInfo() {
      this.dialogVisibleInfo = false;
    },
    hideLoginIn(){
      this.loginIn=false

    },
    logsuccess(){
      this.loginIn=false
      this.userId= this.$store.state.userId
      window.location.reload();

    },
    showRegister(){
      this.loginIn=false
      this.registerIn=true
    },
    forget(){
      this.loginIn=false
      this.forgetIn=true
    },
    hideregisterIn(){
      this.registerIn=false
    },
    hidereforgetIn(){
      this.forgetIn=false
    },
    login(){
      this.loginIn=true
      this.registerIn=false
    },
    pushLogin(){
      this.loginIn=true
    },
    // 修改性别
    sexChange(item){
      this.form.sex=item
      console.log("sex",this.form.sex)
    },
    // 获取期望职位状态
    jobStatus(){
      this.$axios.get(
          "/system/status/list"
      ).then((res) => {
        this.options=res.data.rows

      }).catch(err=>{
        console.log(err)
      });
    },
    // 获取城市
    jobCity(){
      this.$axios.get(
          "/system/city/list"
      ).then((res) => {
        this.optionsCity=res.data.rows

      }).catch(err=>{
        console.log(err)
      });
    },
    // 获取职位状态
    jobType(){
      this.$axios.get(
          "/system/type/list"
      ).then((res) => {
        this.optionsType=res.data.rows

      }).catch(err=>{
        console.log(err)
      });
    },
    // 获取行业
    jobIndustry(){
      this.$axios.get(
          "/system/industry/list"
      ).then((res) => {
        this.optionsIndustry=res.data.rows

      }).catch(err=>{
        console.log(err)
      });
    },
    // 获取薪资
    jobSalary(){
      this.$axios.get(
          "/system/salary/list"
      ).then((res) => {
        this.optionsSalary=res.data.rows

      }).catch(err=>{
        console.log(err)
      });
    },
    // 获取学历
    jobEdu(){
      this.$axios.get(
          "/system/education/list"
      ).then((res) => {
        this.optionsEdu=res.data.rows

      }).catch(err=>{
        console.log(err)
      });
    },
    // 基础信息
    edit(){
      this.editInfo=1
    },

    // 期望求职
    addJob(){
      this.form.expectations.push({
        city: "",
        industry: "",
        position: "",
        salary: "",
        type: "",
        userId: parseInt(this.userId),
        id:Date.now(),
      });
      var data=[{
        city: "",
        industry: "",
        position: "",
        salary: "",
        type: "",
        id:Date.now(),
        userId: parseInt(this.userId)
      }
      ]
      this.$axios.post(
          "/system/expectations",data,{
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          }
      ).then((res) => {
        console.log(res.data)
      }).catch(err=>{
        console.log(err)
      });

    },
    editJob(item){
      item.jobedit=1
      this.$forceUpdate();
    },
    deleteJob(item){
      this.$axios.delete(
          "/system/expectations/"+item.id
      ).then((res) => {
        this.$message({
          message: res.data.msg, //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            //跳转页面或执行方法
            // this.$router.go();
            location.reload();
          },
        });

      }).catch(err=>{
        console.log(err)
      });

    },

    //工作经历
    addwork(){
      this.form.workExperience.push({
        workCompanyName: "",
        workPositionName: "",
        workIndustry: "",
        workStartTime: "",
        workEndTime: "",
        workExperienceContent: "",
        userId: parseInt(this.userId),
        id:Date.now(),
      });
      var data=[{
        city: "",
        industry: "",
        position: "",
        salary: "",
        type: "",
        id:Date.now(),
        userId: parseInt(this.userId)
      }
      ]
      this.$axios.post(
          "/system/work_experience",data,{
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          }
      ).then((res) => {
        console.log(res.data)
      }).catch(err=>{
        console.log(err)
      });

    },
    deleteWork(item){
      this.$axios.delete(
          "/system/work_experience/"+item.id
      ).then((res) => {
        this.$message({
          message: res.data.msg, //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            //跳转页面或执行方法
            location.reload();
          },
        });

      }).catch(err=>{
        console.log(err)
      });
    },
    // 教育经历
    addedu(){

      this.form.eduExperience.push({
        eduEducation: "",
        eduEndTime: "",
        eduExperienceLocation: "",
        eduHonor: "",
        eduPaper: "",
        eduSpeciality: "",
        eduStartTime: "",
        userId: parseInt(this.userId),
        id:Date.now(),
      });
      var data=[{
        eduEducation: "",
        eduEndTime: "",
        eduExperienceLocation: "",
        eduHonor: "",
        eduPaper: "",
        eduSpeciality: "",
        eduStartTime: "",
        userId: parseInt(this.userId),
        id:Date.now(),
      }
      ]
      this.$axios.post(
          "/system/edu_experience",data,{
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          }
      ).then((res) => {
        console.log(res.data)
      }).catch(err=>{
        console.log(err)
      });

    },
    editEdu(item){
      console.log(item)
      item.jobedit=1
      this.$forceUpdate();
    },
    deleteEdu(item){
      this.$axios.delete(
          "/system/edu_experience/"+item.id
      ).then((res) => {
        this.$message({
          message: res.data.msg, //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            //跳转页面或执行方法
            location.reload();
          },
        });

      }).catch(err=>{
        console.log(err)
      });
    },
    // 项目经历
    addPro(){

      this.form.projectExperience.push({
        projectContent: "",
        projectEndTime: "",
        projectName: "",
        projectPerformance: "",
        projectRole: "",
        projectStartTime: "",
        userId: parseInt(this.userId),
        id:Date.now(),
      });
      var data=[{
        projectContent: "",
        projectEndTime: "",
        projectName: "",
        projectPerformance: "",
        projectRole: "",
        projectStartTime: "",
        userId: parseInt(this.userId),
        id:Date.now(),
      }
      ]
      this.$axios.post(
          "/system/project_experience",data,{
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          }
      ).then((res) => {
        console.log(res.data)
      }).catch(err=>{
        console.log(err)
      });
    },
    editPro(item){
      console.log(item)
      item.jobedit=1
      this.$forceUpdate();
    },
    deletePro(item){
      this.$axios.delete(
          "/system/project_experience"+item.id
      ).then((res) => {
        this.$message({
          message: res.data, //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            //跳转页面或执行方法
            location.reload();
          },
        });

      }).catch(err=>{
        console.log(err)
      });
    },
    // 获取简历
    getUser() {
      this.$axios.get(
          "/system/job_user/"+this.userId
      ).then((res) => {
        this.form=res.data.data
        console.log(this.form)
        var birthDate = new Date(this.form.birthday);
        // 获取当前日期
        var today = new Date();
        // 获取当前年份
        var currentYear = today.getFullYear();

        // 计算年龄
        var age = currentYear - birthDate.getFullYear();

        // 如果生日还没有到，年龄需要减去1
        if (today.getMonth() < birthDate.getMonth() ||
            (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
          age--;
        }

        this.form.birthday=age
        console.log(this.form.workExperience.workStartTime)
        // 将日期字符串转换为Date对象
        var start = new Date(this.form.workHour);
        var end = new Date();

        // 计算年份差
        var yearsDiff = end.getFullYear() - start.getFullYear();

        // 如果开始年份的日期大于结束年份的日期，则年份差减1
        if (start.getMonth() > end.getMonth() ||
            (start.getMonth() === end.getMonth() && start.getDate() > end.getDate())) {
          yearsDiff--;
        }

        // 计算月份差
        var monthsDiff = end.getMonth() - start.getMonth();

        // 如果月份差为负数，说明开始年份的日期在结束年份的日期之后，需要调整
        if (monthsDiff < 0) {
          monthsDiff += 12; // 加上12个月
          yearsDiff--;
        }
        console.log("gongzuo",yearsDiff)
        if(yearsDiff>0&&monthsDiff>0){
          this.form.workHour="工作"+yearsDiff+"年零"+monthsDiff+"个月"
        }else if(yearsDiff==0){
          this.form.workHour="工作"+monthsDiff+"个月"
        }
        else if(monthsDiff==0){
          this.form.workHour="工作"+yearsDiff+"年"
        }


        this.form.expectations.forEach(function(element) {
          element.jobedit=0

        });
        this.form.workExperience.forEach(function(element) {
          element.jobedit=0
        });
        this.form.eduExperience.forEach(function(element) {
          element.jobedit=0
        });
        this.form.projectExperience.forEach(function(element) {
          element.jobedit=0
        });

      }).catch(err=>{
        console.log(err)
      });
    },
    // 期望求职
    cancleSubmit(){
      this.getUser()
    },
    confirmSubmit() {
      if (!this.form.userName) {
        this.$message.error("请输入您的昵称");
        return false;
      }

      if (this.form.sex===null) {
        this.$message.error("请选择您的性别");
        return false;
      }
      if (!this.form.birthday) {
        this.$message.error("请选择您的出生年月");
        return false;
      }
      if (!this.form.phonenumber) {
        this.$message.error("请输入您的手机号码");
        return false;
      }
      if (!this.form.email) {
        this.$message.error("请输入您的邮箱");
        return false;
      }

      // this.form.status=this.form.jobStatus
      userUpdate(this.form).then((res) => {
        localStorage.setItem("typeInfo", 1);
        this.$store.state.typeInfo = 1;
        this.$message({
          message: res.msg, //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            //跳转页面或执行方法
            location.reload();
          },
        });
      });
    },
    // 期望
    confirmSubmitJob(jobs){
      var formJob={
        id: jobs.id,
        userId: jobs.userId,
        type: jobs.type,
        position: jobs.position,
        salary: jobs.salary,
        industry:jobs.industry,
        city: jobs.city
      }
      if(!formJob.type){
        this.$message.error("请输入您期望的职位类型");
        return false;
      }
      if(!formJob.position){
        this.$message.error("请输入您期望的职位");
        return false;
      }
      if(!formJob.salary){
        this.$message.error("请输入您期望的薪资范围");
        return false;
      }
      if(!formJob.industry){
        this.$message.error("请输入您期望的工作行业");
        return false;
      }
      if(!formJob.city){
        this.$message.error("请输入您期望的工作地点");
        return false;
      }

      jobUpdate(formJob).then((res) => {
        this.$message({
          message: res.msg, //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            //跳转页面或执行方法
            location.reload();
          },
        });
      });
    },
    cancleSubmitJob(){
      this.getUser()
    },
    // 工作
    confirmSubmitWork(jobs){
      var formJob={
        id: jobs.id,
        userId: jobs.userId,
        workCompanyName: jobs.workCompanyName,
        workEndTime: jobs.workEndTime,
        workExperienceContent: jobs.workExperienceContent,
        workIndustry:jobs.workIndustry,
        workPositionName: jobs.workPositionName,
        workStartTime: jobs.workStartTime
      }
      if(!formJob.workCompanyName){
        this.$message.error("请输入您任职的公司名称");
        return false;
      }
      if(!formJob.workStartTime){
        this.$message.error("请输入您的入职时间");
        return false;
      }
      if(!formJob.workEndTime){
        this.$message.error("请输入您的离职时间");
        return false;
      }
      if(!formJob.workIndustry){
        this.$message.error("请输入您期望的工作行业");
        return false;
      }
      if(!formJob.workExperienceContent){
        this.$message.error("请输入您期望的工作内容");
        return false;
      }
      workUpdate(formJob).then((res) => {
        this.$message({
          message: res.msg, //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            //跳转页面或执行方法
            location.reload();
          },
        });
      });
    },
    cancleSubmitWork(){
      this.getUser()
    },
    // 教育
    confirmSubmitEdu(jobs){
      var formJob={
        eduEducation: jobs.eduEducation,
        eduEndTime: jobs.eduEndTime,
        eduExperienceLocation:jobs.eduExperienceLocation,
        eduHonor:jobs.eduHonor,
        eduPaper:jobs.eduPaper,
        eduSpeciality: jobs.eduSpeciality,
        eduStartTime: jobs.eduStartTime,
        id: jobs.id,
        userId: jobs.userId
      }
      if(!formJob.eduExperienceLocation){
        this.$message.error("请输入您的学校名称");
        return false;
      }
      if(!formJob.eduStartTime){
        this.$message.error("请输入您的入学时间");
        return false;
      }
      if(!formJob.eduEndTime){
        this.$message.error("请输入您的毕业时间");
        return false;
      }

      if(!formJob.eduHonor){
        this.$message.error("请输入您在校期间所获荣誉");
        return false;
      }
      eduUpdate(formJob).then((res) => {
        this.$message({
          message: res.msg, //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            //跳转页面或执行方法
            location.reload();
          },
        });
      });
    },
    cancleSubmitEdu(){
      this.getUser()
    },
    // 项目
    confirmSubmitPro(jobs){
      var formJob={

        id: jobs.id,
        projectContent: jobs.projectContent,
        projectEndTime:jobs.projectEndTime,
        projectName: jobs.projectName,
        projectPerformance: jobs.projectPerformance,
        projectRole: jobs.projectRole,
        projectStartTime:jobs.projectStartTime ,
        userId: jobs.userId
      }
      if(!formJob.projectName){
        this.$message.error("请输入项目名称");
        return false;
      }
      if(!formJob.projectStartTime){
        this.$message.error("请输入项目开始时间");
        return false;
      }
      if(!formJob.projectEndTime){
        this.$message.error("请输入项目结束时间");
        return false;
      }

      if(!formJob.projectContent){
        this.$message.error("请简要介绍您的项目");
        return false;
      }
      if(!formJob.projectPerformance){
        this.$message.error("请简要介绍您的项目成果");
        return false;
      }
      proUpdate(formJob).then((res) => {
        this.$message({
          message: res.msg, //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            //跳转页面或执行方法
            location.reload();
          },
        });
      });
    },
    cancleSubmitPro(){
      this.getUser()
    },
    handleAvatarSuccess(res) {
      this.form.avatar = res.fileName;
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    // 文件上传失败（http）
    handleAvatarError() {
      this.$message.error("上传失败! （http失败）");
    },

  },
};
</script>

<style scoped lang="scss">
.ViewTitle {
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #ebebeb;
  font-size: 16px;
  color: #333333;
  font-weight: 400;
  padding: 0 40px;
  text-align: center;
}
</style>
